import { type Identifier, type BaseModel, type ActionBy } from 'appTypes'
import { type WithCreatedField } from 'resources/base'
import { type WithCustomer } from 'resources/customers'
import { type WithPaymentTerm } from 'resources/paymentTerms'
import { type UnitModel } from 'resources/units'
import { type WithWo } from 'resources/workOrders'
import { type WithShop } from 'resourcesBase'

export interface InvoiceModel
    extends BaseModel,
        WithCreatedField,
        WithShop,
        WithWo,
        WithCustomer,
        WithPaymentTerm {
    number: string
    type: InvoiceTypeKeys
    status: InvoiceStatusKeys
    dueDate: string
    invoiceDate: string
    closedOn: string
    total: number
    amountPaid: number
    amountDue: number
    unit: Identifier
    unitData: UnitModel
    poNumber: string
    statusUpdatedBy: ActionBy
    statusUpdated: string
    cancellationReason: string
}

export interface SalesOrderModel extends BaseModel, WithCreatedField, WithCustomer {
    canceledOn: string
    cancellationReason: string
    closedOn: string
    description: string
    invoiceData: InvoiceModel
    number: string
    poNumber: string
    type: InvoiceTypeKeys
    status: InvoiceStatusKeys
}

export enum InvoiceTypeKeys {
    DIRECT = 'DIRECT',
    WORK_ORDER = 'WORK_ORDER',
}

export enum InvoiceStatusKeys {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}
