import { inject, observer } from 'mobx-react'
import { ListBase, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { type AuthStore, type Action, ActionsMenu, useCanAccess } from 'core'
import { Link } from 'lib'
import { CustomersSection } from 'resources/customers'
import {
    useIsVenderWo,
    WoJobDrawerToggler,
    woOperations,
    type WorkOrderModel,
} from 'resources/workOrders'
import { FlexReverse, IconBox, PageContent } from 'ui'

import { WorkOrderJobsResource, WorkOrderJobsSlider } from '../Jobs'
import { WorkOrderDetails, WorkOrderPurchaseInvoice, WorkOrderTopSection } from '../components'

import { InvoiceSection, VendorSection } from './components'

const jobsActions: Action<WorkOrderModel> = (params, { children }) => [
    <WoJobDrawerToggler
        key="add-job"
        children={(open) => children({ Icon: Icons.Add, title: 'Add Job', onClick: open })}
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'jobs',
    }),
]

const JobsListAction = () => {
    return (
        <Link to="jobs">
            <IconBox title="See All">
                <Icons.GridView />
            </IconBox>
        </Link>
    )
}

const WorkOrderOverview = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { record: workOrder, isLoading } = useShowContext<WorkOrderModel>()
        const isVendorWorkOrder = useIsVenderWo()
        const isStatusCancel = workOrder?.status === 'CANCELED'
        const getIsAllowed = useCanAccess()
        const { value } = getIsAllowed(woOperations.jobs)

        return (
            <div>
                <WorkOrderTopSection />
                <PageContent>
                    {isStatusCancel ? (
                        <WorkOrderDetails />
                    ) : (
                        <FlexReverse>
                            <WorkOrderDetails />
                            {auth.companySettings.hasCustomers && (
                                <CustomersSection
                                    customer={workOrder?.unitData?.customerData}
                                    isLoading={isLoading}
                                />
                            )}
                            {auth.companySettings.hasInvoicing && <InvoiceSection />}
                            <VendorSection />
                            <WorkOrderJobsResource>
                                <ListBase perPage={3}>
                                    <WorkOrderJobsSlider
                                        action={
                                            value ? (
                                                <ActionsMenu
                                                    record={workOrder}
                                                    actions={jobsActions}
                                                />
                                            ) : (
                                                <JobsListAction />
                                            )
                                        }
                                    />
                                </ListBase>
                            </WorkOrderJobsResource>
                            {isVendorWorkOrder ? <WorkOrderPurchaseInvoice /> : null}
                        </FlexReverse>
                    )}
                </PageContent>
            </div>
        )
    }),
)

export default WorkOrderOverview
